import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, catchError, filter, from, of, switchMap, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { GlobalAppConfigurationService } from '../../services/global-app-configuration.service';
import { TenableScansFireStoreService } from './tenable-scans-fire-store.service';
import { TenableScanFireStoreDetailsService } from './tenable-scan-fire-store-details.service';
import { CompanyService } from '@core/services/company.service';
import { ErrorHandlerService } from '@core/services/error-handler.service';


/**
 * https://developer.tenable.com/reference/scans-launch
 * Scan Actions:
 * Launch
 * Launch Rollover
 * Custom Start
 * Stop
 * Pause
 * Edit
 * Copy
 * Trash
 *
 * List scans
 * Get scan details
 * Update scan
 * Delete scan
 */
@Injectable({
  providedIn: 'root'
})
export class TenableScansService {
  private defaultCacheDuration = 7 * 24 * 60 * 60 * 1000; // Default cache duration: 7 days in milliseconds
  private tempCacheDuration = 20 * 60 * 1000; // Temporary cache duration: 20 minute in milliseconds
  public companySlug?: string;
  private functionUrl = environment.base_function_url + '/api/tenable';
  private companyHeaderObject?: { company_slug: string; };
  public apiTokenSource = new BehaviorSubject<string | null>(null);
  public apiToken$ = this.apiTokenSource.asObservable();

  constructor(
    private http: HttpClient,
    private globalConfigService: GlobalAppConfigurationService,
    private tenableScansFireStoreService: TenableScansFireStoreService,
    private tenableScansDetailsFireStoreService: TenableScanFireStoreDetailsService,
    private companyService: CompanyService,
    private errorService: ErrorHandlerService
  ) {
    this.setCompanySlug();
    this.globalConfigService.getFunctionToken().then((tokenData: any) => {
      if (tokenData) {
        this.apiTokenSource.next(tokenData.value);
      }
    })
  }


  /**
   * Sets the company slug for the current user.
   */
  setCompanySlug() {
    const companySlug = localStorage.getItem('companySlug');
    if (companySlug) {
      this.companySlug = companySlug;
      this.companyHeaderObject = { "company_slug": this.companySlug }
    } else {

      this.companyService.getCompanySlug().subscribe((slug: string | null) => {
        if (slug) {
          localStorage.setItem('companySlug', slug);
          this.companySlug = slug;
          this.companyHeaderObject = { "company_slug": this.companySlug }
        }
      });
    }
  }
  // /api/tenable/create-scan
  createScan(payload: any) {
    return this.apiToken$.pipe(
      filter(token => token !== null),
      switchMap(token => {
        if (!token) {
          // Handle case where token is not yet available
          return []; // Return an empty observable or handle as needed
        }
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'x-functions-key': token
          })
        };

        const finalPayload = { ...payload, ...this.companyHeaderObject };

        return this.http.post(this.functionUrl + '/create-scan', finalPayload, httpOptions);
      })
    );
  }

  // tenable/list-scan
  listScans(): Observable<any[]> {

    return this.apiToken$.pipe(
      filter(token => token !== null), // Ensure we have a token
      switchMap(token => {
        if (!token) {
          return of([]);
        }

        this.getNewScans(token).subscribe(() => { });

        return from(this.tenableScansFireStoreService.getScans()).pipe(
          switchMap((firestoreData: any) => {
            if ((firestoreData && firestoreData.length > 0)) {
              // If Firestore returned data, use it
              // remove scans with the name Baseline Inventory Default and status empty
              firestoreData = firestoreData.filter((scan: any) => {
                // return scan.name !== 'Baseline Inventory Default' || scan.status !== 'empty';
                return scan.name !== 'Baseline Inventory Default';
              });
              return of(firestoreData);
            } else {
              // Firestore data is empty or needs an update, fetch from the API
              return this.getNewScans(token);
            }
          }),
          catchError(error => {
            console.error('Error fetching data from Firestore:', error);
            this.errorService.handleError(error);
            return throwError(() => new Error('Error fetching data from Firestore'));
          })
        );
      })
    );
  }

  private getNewScans(token: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-functions-key': token
      })
    };
    return this.http.post<any[]>(this.functionUrl + '/list-scan', this.companyHeaderObject, httpOptions).pipe(
      switchMap((apiData: any) => {
        // Optionally check if apiData is valid before updating Firestore
        if (apiData && apiData.scans && apiData.scans.length > 0) {

          let scanData = apiData.scans;
          // Update Firestore with the new data from the API
          // remove scans with the name Baseline Inventory Default and status empty
          scanData = scanData.filter((scan: any) => {
            // return scan.name !== 'Baseline Inventory Default' || scan.status !== 'empty';
            return scan.name !== 'Baseline Inventory Default';
          });
          return from(this.tenableScansFireStoreService.updateScans(scanData)).pipe(
            // After updating Firestore, return the API data as the new source of truth
            switchMap(() => of(scanData))
          );
        } else {
          // API data is not valid, return an empty array
          return of([]);
        }
      }),
      catchError(error => {
        console.error('Error fetching or processing data from the function URL:', error);
        this.errorService.handleError(error);
        return throwError(() => new Error('Error fetching or processing data from the function URL'));
      })
    );
  }

  // "tenable/scans/{scan_id}"
  getScanDetails(scan_id: string, forceRefresh = false): Observable<any> {
    if (forceRefresh) {
      this.tempCacheDuration = 0;
    }
    return this.apiToken$.pipe(
      filter(token => token !== null), // Ensure we have a token
      switchMap(token => {
        if (!token) {
          throw new Error('Token is null');
        }
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'x-functions-key': token
          })
        };

        // Attempt to get the scan from Firestore first
        return from(this.tenableScansDetailsFireStoreService.getScan(scan_id)).pipe(
          switchMap(firestoreData => {
            if (firestoreData && !this.isCacheExpired(firestoreData.updatedAt)) {
              // If Firestore returned data and cache is not expired, use it
              return of(firestoreData);
            } else {
              // If not found in Firestore or cache expired, fetch from the API
              const finalPayload = { scan_id, ...this.companyHeaderObject };
              return this.http.post<any>(`${this.functionUrl}/scans/${scan_id}`, finalPayload, httpOptions).pipe(
                switchMap(apiData => {
                  if (apiData.uid) {
                    // Update Firestore with the new scan data from the API
                    return from(this.tenableScansDetailsFireStoreService.updateScan(apiData)).pipe(
                      // After updating Firestore, return the API data as the new source of truth
                      switchMap(() => of(apiData))
                    );
                  } else if (apiData.error) {
                    // API returned an error, console the error message and return Firestore data
                    console.error('API returned an error:', apiData.error);
                    return of(firestoreData);
                    // return throwError(() => new Error(apiData.error));
                  } else {
                    // API data is not valid or empty, return Firestore data
                    return of(firestoreData);
                  }
                }),
                catchError(error => {
                  console.error('Error fetching or processing data from the function URL:', error);
                  this.errorService.handleError(error);
                  return throwError(() => new Error('Error fetching or processing data from the function URL'));
                })
              );
            }
          }),
          catchError(error => {
            console.error('Error fetching data from Firestore:', error);
            this.errorService.handleError(error);
            return throwError(() => new Error('Error fetching data from Firestore'));
          })
        );
      }),
      catchError(error => {
        console.error('Error in getScanDetails method:', error);
        this.errorService.handleError(error);
        return throwError(() => new Error('Error in getScanDetails method'));
      })
    );
  }


  // tenable/update-scan-status
  updateScanStatus(scan_id: string) {
    return this.apiToken$.pipe(
      filter(token => token !== null),
      switchMap(token => {
        if (!token) {
          // Handle case where token is not yet available
          return []; // Return an empty observable or handle as needed
        }
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'x-functions-key': token
          })
        };

        const payload = {
          scan_id: scan_id
        };

        const finalPayload = { ...payload, ...this.companyHeaderObject };

        return this.http.post(this.functionUrl + '/update-scan-status', finalPayload, httpOptions);
      })
    );
  }

  deleteScan(scan_id: string) {
    return this.apiToken$.pipe(
      filter(token => token !== null),
      switchMap(token => {
        if (!token) {
          // Handle case where token is not yet available
          return []; // Return an empty observable or handle as needed
        }
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'x-functions-key': token
          }),
          body: {
            scan_id: scan_id,
            ...this.companyHeaderObject
          }
        };


        this.tenableScansDetailsFireStoreService.delete(scan_id);
        return this.http.delete(this.functionUrl + '/delete-scan', httpOptions);
      })
    );
  }

  // "tenable/pause-scan"
  pauseScan(scan_id: string) {
    return this.apiToken$.pipe(
      filter(token => token !== null),
      switchMap(token => {
        if (!token) {
          // Handle case where token is not yet available
          return []; // Return an empty observable or handle as needed
        }
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'x-functions-key': token
          })
        };

        const payload = {
          scan_id: scan_id
        };
        const finalPayload = { ...payload, ...this.companyHeaderObject };

        return this.http.post(this.functionUrl + '/pause-scan', finalPayload, httpOptions);
      })
    );
  }

  // "tenable/stop-scan"
  stopScan(scan_id: string) {
    return this.apiToken$.pipe(
      filter(token => token !== null),
      switchMap(token => {
        if (!token) {
          // Handle case where token is not yet available
          return []; // Return an empty observable or handle as needed
        }
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'x-functions-key': token
          })
        };

        const payload = {
          scan_id: scan_id
        };
        const finalPayload = { ...payload, ...this.companyHeaderObject };

        return this.http.post(this.functionUrl + '/stop-scan', finalPayload, httpOptions);
      })
    );
  }


  startScan(scan_id: string) {
    return this.apiToken$.pipe(
      filter(token => token !== null),
      switchMap(token => {
        if (!token) {
          // Handle case where token is not yet available
          return []; // Return an empty observable or handle as needed
        }
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'x-functions-key': token
          })
        };

        const payload = {
          scan_id: scan_id
        };
        const finalPayload = { ...payload, ...this.companyHeaderObject };

        return this.http.post(this.functionUrl + '/start-scan', finalPayload, httpOptions);
      })
    );
  }


  launchScan(scan_id: string) {
    return this.apiToken$.pipe(
      filter(token => token !== null),
      switchMap(token => {
        if (!token) {
          // Handle case where token is not yet available
          return []; // Return an empty observable or handle as needed
        }
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'x-functions-key': token
          })
        };

        const payload = {
          scan_id: scan_id
        };
        const finalPayload = { ...payload, ...this.companyHeaderObject };

        return this.http.post(this.functionUrl + '/launch-scan', finalPayload, httpOptions);
      })
    );
  }


  resumeScan(scan_id: string) {
    return this.apiToken$.pipe(
      filter(token => token !== null),
      switchMap(token => {
        if (!token) {
          // Handle case where token is not yet available
          return []; // Return an empty observable or handle as needed
        }
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'x-functions-key': token
          })
        };

        const payload = {
          scan_id: scan_id
        };
        const finalPayload = { ...payload, ...this.companyHeaderObject };

        return this.http.post(this.functionUrl + '/resume-scan', finalPayload, httpOptions);
      })
    );
  }

  isCacheExpired(time: string): boolean {
    const cacheTime = new Date(time).getTime();
    const currentTime = new Date().getTime();
    return currentTime - cacheTime > this.tempCacheDuration;
  }

  createAgentGroup(groupName: string) {
    return this.apiToken$.pipe(
      filter(token => token !== null),
      switchMap(token => {
        if (!token) {
          // Handle case where token is not yet available
          return []; // Return an empty observable or handle as needed
        }
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'x-functions-key': token
          })
        };

        const payload = {
          name: groupName
        };
        const finalPayload = { ...payload, ...this.companyHeaderObject };

        return this.http.post(this.functionUrl + '/create-agent-group', finalPayload, httpOptions);
      })
    );
  }

  addAgentToGroup(groupId: number, agentId: string) {
    return this.apiToken$.pipe(
      filter(token => token !== null),
      switchMap(token => {
        if (!token) {
          // Handle case where token is not yet available
          return []; // Return an empty observable or handle as needed
        }
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'x-functions-key': token
          })
        };

        const payload = {
          group_id: groupId,
          agent_id: agentId
        };
        const finalPayload = { ...payload, ...this.companyHeaderObject };

        return this.http.post(this.functionUrl + '/add-agent-to-group', finalPayload, httpOptions);
      })
    );
  }
}
