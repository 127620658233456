import { ɵgetAllInstancesOf as _getAllInstancesOf, ɵgetDefaultInstanceOf as _getDefaultInstanceOf, VERSION, ɵAngularFireSchedulers as _AngularFireSchedulers, ɵzoneWrap as _zoneWrap } from '@angular/fire';
import { timer, from } from 'rxjs';
import { concatMap, distinct } from 'rxjs/operators';
import { isPlatformServer } from '@angular/common';
import * as i0 from '@angular/core';
import { InjectionToken, Optional, PLATFORM_ID, NgModule, makeEnvironmentProviders, NgZone, Injector } from '@angular/core';
import { FirebaseApp, FirebaseApps } from '@angular/fire/app';
import { registerVersion } from 'firebase/app';
import { getAll as getAll$1, getBoolean as getBoolean$1, getNumber as getNumber$1, getString as getString$1, getValue as getValue$1 } from 'rxfire/remote-config';
import { activate as activate$1, ensureInitialized as ensureInitialized$1, fetchAndActivate as fetchAndActivate$1, fetchConfig as fetchConfig$1, getAll as getAll$2, getBoolean as getBoolean$2, getNumber as getNumber$2, getRemoteConfig as getRemoteConfig$1, getString as getString$2, getValue as getValue$2, isSupported as isSupported$1, setLogLevel as setLogLevel$1 } from 'firebase/remote-config';
export * from 'firebase/remote-config';
class RemoteConfig {
  constructor(remoteConfig) {
    return remoteConfig;
  }
}
const REMOTE_CONFIG_PROVIDER_NAME = 'remote-config';
class RemoteConfigInstances {
  constructor() {
    return _getAllInstancesOf(REMOTE_CONFIG_PROVIDER_NAME);
  }
}
const remoteConfigInstance$ = timer(0, 300).pipe(concatMap(() => from(_getAllInstancesOf(REMOTE_CONFIG_PROVIDER_NAME))), distinct());
const PROVIDED_REMOTE_CONFIG_INSTANCES = new InjectionToken('angularfire2.remote-config-instances');
function defaultRemoteConfigInstanceFactory(provided, defaultApp, platformId) {
  if (isPlatformServer(platformId)) {
    return null;
  }
  const defaultRemoteConfig = _getDefaultInstanceOf(REMOTE_CONFIG_PROVIDER_NAME, provided, defaultApp);
  return defaultRemoteConfig && new RemoteConfig(defaultRemoteConfig);
}
function remoteConfigInstanceFactory(fn) {
  return (zone, injector, platformId) => {
    if (isPlatformServer(platformId)) {
      return null;
    }
    const remoteConfig = zone.runOutsideAngular(() => fn(injector));
    return new RemoteConfig(remoteConfig);
  };
}
const REMOTE_CONFIG_INSTANCES_PROVIDER = {
  provide: RemoteConfigInstances,
  deps: [[new Optional(), PROVIDED_REMOTE_CONFIG_INSTANCES]]
};
const DEFAULT_REMOTE_CONFIG_INSTANCE_PROVIDER = {
  provide: RemoteConfig,
  useFactory: defaultRemoteConfigInstanceFactory,
  deps: [[new Optional(), PROVIDED_REMOTE_CONFIG_INSTANCES], FirebaseApp, PLATFORM_ID]
};
class RemoteConfigModule {
  constructor() {
    registerVersion('angularfire', VERSION.full, 'rc');
  }
  static ɵfac = function RemoteConfigModule_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || RemoteConfigModule)();
  };
  static ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: RemoteConfigModule
  });
  static ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
    providers: [DEFAULT_REMOTE_CONFIG_INSTANCE_PROVIDER, REMOTE_CONFIG_INSTANCES_PROVIDER]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(RemoteConfigModule, [{
    type: NgModule,
    args: [{
      providers: [DEFAULT_REMOTE_CONFIG_INSTANCE_PROVIDER, REMOTE_CONFIG_INSTANCES_PROVIDER]
    }]
  }], () => [], null);
})();
function provideRemoteConfig(fn, ...deps) {
  registerVersion('angularfire', VERSION.full, 'rc');
  return makeEnvironmentProviders([DEFAULT_REMOTE_CONFIG_INSTANCE_PROVIDER, REMOTE_CONFIG_INSTANCES_PROVIDER, {
    provide: PROVIDED_REMOTE_CONFIG_INSTANCES,
    useFactory: remoteConfigInstanceFactory(fn),
    multi: true,
    deps: [NgZone, Injector, PLATFORM_ID, _AngularFireSchedulers, FirebaseApps, ...deps]
  }]);
}

// DO NOT MODIFY, this file is autogenerated by tools/build.ts
const getAllChanges = _zoneWrap(getAll$1, true);
const getBooleanChanges = _zoneWrap(getBoolean$1, true);
const getNumberChanges = _zoneWrap(getNumber$1, true);
const getStringChanges = _zoneWrap(getString$1, true);
const getValueChanges = _zoneWrap(getValue$1, true);

// DO NOT MODIFY, this file is autogenerated by tools/build.ts
const activate = _zoneWrap(activate$1, true);
const ensureInitialized = _zoneWrap(ensureInitialized$1, true);
const fetchAndActivate = _zoneWrap(fetchAndActivate$1, true);
const fetchConfig = _zoneWrap(fetchConfig$1, true);
const getAll = _zoneWrap(getAll$2, true);
const getBoolean = _zoneWrap(getBoolean$2, true);
const getNumber = _zoneWrap(getNumber$2, true);
const getRemoteConfig = _zoneWrap(getRemoteConfig$1, true);
const getString = _zoneWrap(getString$2, true);
const getValue = _zoneWrap(getValue$2, true);
const isSupported = _zoneWrap(isSupported$1, true);
const setLogLevel = _zoneWrap(setLogLevel$1, true);

/**
 * Generated bundle index. Do not edit.
 */

export { RemoteConfig, RemoteConfigInstances, RemoteConfigModule, activate, ensureInitialized, fetchAndActivate, fetchConfig, getAll, getAllChanges, getBoolean, getBooleanChanges, getNumber, getNumberChanges, getRemoteConfig, getString, getStringChanges, getValue, getValueChanges, isSupported, provideRemoteConfig, remoteConfigInstance$, setLogLevel };
