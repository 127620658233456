{
  "name": "scan-ninja-dashboard",
  "version": "0.0.41",
  "scripts": {
    "ng": "ng",
    "start": "ng serve  --configuration local",
    "codespaces": "ng serve --host=0.0.0.0 --disable-host-check",
    "build": "ng build",
    "build:dev": "ng build --configuration development",
    "build:prod": "ng build --configuration production",
    "watch": "ng build --watch --configuration local",
    "deploy:hosting": "ng build --configuration production && firebase deploy --only hosting",
    "test": "ng test",
    "lint": "ng lint"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^19.0.5",
    "@angular/common": "^19.0.5",
    "@angular/compiler": "^19.0.5",
    "@angular/core": "^19.0.5",
    "@angular/fire": "19.0.0-rc.4",
    "@angular/forms": "^19.0.5",
    "@angular/platform-browser": "^19.0.5",
    "@angular/platform-browser-dynamic": "^19.0.5",
    "@angular/router": "^19.0.5",
    "@microsoft/applicationinsights-web": "^3.3.4",
    "@ng-bootstrap/ng-bootstrap": "^18.0.0",
    "@popperjs/core": "^2.11.8",
    "@stripe/stripe-js": "^5.4.0",
    "bootstrap": "^5.3.3",
    "bootstrap-icons": "^1.11.3",
    "chart.js": "^4.4.7",
    "firebase": "^11.1.0",
    "jquery": "^3.7.1",
    "jquery-ui": "^1.14.1",
    "ngx-highlight-js": "^19.0.0",
    "ngx-highlightjs": "^13.0.0",
    "rxjs": "~7.8.1",
    "tslib": "^2.8.1",
    "zone.js": "~0.15.0"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^19.0.6",
    "@angular-eslint/builder": "^19.0.2",
    "@angular-eslint/eslint-plugin": "^19.0.2",
    "@angular-eslint/eslint-plugin-template": "^19.0.2",
    "@angular-eslint/schematics": "^19.0.2",
    "@angular-eslint/template-parser": "^19.0.2",
    "@angular/cli": "~19.0.6",
    "@angular/compiler-cli": "^19.0.5",
    "@angular/localize": "^19.0.5",
    "@eslint/js": "^9.17.0",
    "@types/jasmine": "~5.1.5",
    "@typescript-eslint/eslint-plugin": "^8.19.0",
    "@typescript-eslint/parser": "^8.19.0",
    "eslint": "^9.17.0",
    "eslint-plugin-utils": "^0.3.2",
    "firebase-tools": "^13.29.1",
    "jasmine-core": "~5.5.0",
    "karma": "~6.4.4",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.1",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "ts-evaluator": "^2.0.0",
    "typescript": "~5.6.0",
    "typescript-eslint": "^8.19.0",
    "webpack-dev-middleware": "^7.4.2"
  },
  "engines": {
    "node": ">=18.20.3 || >=20.13.1"
  }
}
