{
  "roles": [
    {
      "id": "1e7b1d8e-3f8b-4d6b-9b2d-1a2b3c4d5e6f",
      "name": "Super Admin",
      "permissions": [
        "configure_system",
        "manage_user_roles",
        "set_security_policies",
        "maintain_security",
        "maintain_functionality",
        "access_all_data",
        "access_security_logs",
        "access_threat_intelligence",
        "access_vulnerability_reports",
        "investigate_incidents",
        "monitor_security_alerts",
        "perform_threat_analysis",
        "access_incident_reports",
        "mitigate_threats",
        "coordinate_with_teams",
        "resolve_security_issues",
        "access_audit_logs",
        "access_compliance_reports",
        "perform_compliance_checks",
        "manage_user_accounts",
        "reset_passwords",
        "provide_technical_support",
        "access_specific_applications",
        "access_relevant_data"
      ]
    },
    {
      "id": "2f8c2e9f-4g9c-5e7c-0d3e-2b3c4d5e6f7g",
      "name": "Administrator",
      "permissions": [
        "configure_system",
        "manage_user_roles",
        "set_security_policies",
        "maintain_security",
        "maintain_functionality"
      ]
    },
    {
      "id": "2f8c2e9f-4g9c-5e7c-0d3e-2b3c4d5e6f7g",
      "name": "Security Analyst",
      "permissions": [
        "access_security_logs",
        "access_threat_intelligence",
        "access_vulnerability_reports",
        "investigate_incidents",
        "monitor_security_alerts",
        "perform_threat_analysis",
        "access_incident_reports"
      ]
    },
    {
      "id": "3g9d3f0g-5h0d-6f8d-1e4f-3c4d5e6f7g8h",
      "name": "Incident Responder",
      "permissions": [
        "access_incident_reports",
        "mitigate_threats",
        "coordinate_with_teams",
        "resolve_security_issues"
      ]
    },
    {
      "id": "4h0e4g1h-6i1e-7g9e-2f5g-4d5e6f7g8h9i",
      "name": "Compliance / Audit",
      "permissions": [
        "access_audit_logs",
        "access_compliance_reports",
        "perform_compliance_checks"
      ]
    },
    {
      "id": "5i1f5h2i-7j2f-8h0f-3g6h-5e6f7g8h9i0j",
      "name": "IT Support",
      "permissions": [
        "manage_user_accounts",
        "reset_passwords",
        "provide_technical_support"
      ]
    },
    {
      "id": "6j2g6i3j-8k3g-9i1g-4h7i-6f7g8h9i0j1k",
      "name": "End User",
      "permissions": [
        "access_specific_applications",
        "access_relevant_data"
      ]
    }
  ]
}
